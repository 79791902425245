import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

export const useLanguage = () => {
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();
  
  if (preferredLanguage) {
    return preferredLanguage;
  }
  
  if (defaultLanguage) {
    return defaultLanguage;
  }
  
  return "en-US";
}
